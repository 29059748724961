import React, { useEffect, useState, useRef } from "react";
import { navigate } from "@reach/router"

const timelineData = [
    {
        id: "t1",
        title: "CEO & Founder",
        start: "Feb 2024",
        end: "Present",
        description: "Founded Creative Web Development Studio (Brigada), specializing in innovative digital solutions. Responsible for leading the team, managing client relationships, and delivering high-quality projects across industries.",
        skills: ["Leadership", "Web Development", "AI Integration", "Project Management"],
        projects: "https://brigada.dev/",
        pic: "https://ardit.dev/12.png"
    },
    {
        id: "t2",
        title: "Direktiva.org",
        start: "Feb 2024",
        end: "Present",
        description: "Developed Kosovo's First News Aggregator platform centralizing news from diverse media outlets, providing comprehensive and reliable information.",
        skills: ["Web Development", "Data Aggregation", "UI/UX Design"],
        projects: "https://direktiva.org/",
        pic: "https://cdn.simpleicons.org/googlenews/000000"
    },
    {
        id: "t3",
        title: "Apunon.com",
        start: "Feb 2024",
        end: "Present",
        description: "Designed Kosovo's First Service Status Platform, providing real-time monitoring and reporting of service interruptions across industries.",
        skills: ["Web Development", "Monitoring Systems", "Data Visualization"],
        projects: "https://apunon.com/",
        pic: "https://cdn.simpleicons.org/amazoncloudwatch/000000"
    },
    {
        id: "t4",
        title: "Niche Solutions",
        start: "Feb 2024",
        end: "Present",
        description: "Built a Python-based platform that compiles real estate investment opportunities using web scraping. Created a centralized database for seamless data management.",
        skills: ["Python", "Web Scraping", "Database Design"],
        projects: "https://nicherealestate.solutions/",
        pic: "https://cdn.simpleicons.org/python/000000"
    },
    {
        id: "t5",
        title: "Unicorn AI",
        start: "Feb 2024",
        end: "Present",
        description: "Led development of an AI-powered platform offering versatile assistance across text, image, and multimedia domains.",
        skills: ["AI Engineering", "Algorithm Development", "Full Stack Development"],
        projects: "https://unicorn.ai/",
        pic: "https://cdn.simpleicons.org/openai/000000"
    },
    {
        id: "t6",
        title: "Police Reports AI",
        start: "Feb 2024",
        end: "Present",
        description: "Developed an AI platform streamlining law enforcement report generation with advanced algorithm design and user-friendly interfaces.",
        skills: ["AI Development", "Model Optimization", "UX Design"],
        projects: "https://policereports.ai/",
        pic: "https://cdn.simpleicons.org/claude/000000"
    },
    {
        id: "t7",
        title: "Cargo Poster",
        start: "Feb 2024",
        end: "Present",
        description: "Developed an EU-funded logistics platform connecting shippers and carriers with real-time matching algorithms and secure transactions.",
        skills: ["Backend Development", "Real-time Systems", "API Integration"],
        projects: "https://cargoposter.eu/",
        pic: "https://cdn.simpleicons.org/dhl/000000"
    },
    {
        id: "t8",
        title: "Software Engineer",
        start: "Mar 2024",
        end: "Feb 2025",
        description: "Worked full-time for Downshift, a US-based company, delivering high-quality platforms like Surf Wisely and Launch Family.",
        skills: ["Laravel", "JavaScript", "API Development", "Full Stack Development", "Vue.js"],
        projects: ["https://surfwisely.com/", "https://launchfamily.com/"],
        pic: "https://cdn.simpleicons.org/vuedotjs/000000"
    },
    {
        id: "t9",
        title: "AI Consultant (Project-Based)",
        start: "May 2024",
        end: "Aug 2024",
        description: "Worked with Hikimore to integrate AI functionalities for platforms like Launch Family and Relate. Delivered optimized algorithms for user-focused features.",
        skills: ["AI Integration", "Machine Learning", "Algorithm Development"],
        projects: "https://hikimore.com/",
        pic: "https://cdn.simpleicons.org/chatbot/000000"
    },
    {
        id: "t10",
        title: "Data Visualization Developer (Project-Based)",
        start: "Jul 2024",
        end: "Sep 2024",
        description: "Designed a survey system for Teachy, delivering dynamic PDFs, statistics, and interactive charts.",
        skills: ["Data Visualization", "Survey Development", "Charting Libraries", "PDF Generation"],
        projects: "https://teachy.ch/",
        pic: "https://cdn.simpleicons.org/chartdotjs/000000"
    },
    {
        id: "t11",
        title: "Development Team Lead",
        start: "Jan 2023",
        end: "Present",
        description: "Led a team at StarLabs to develop large-scale web applications for GiG clients, including CasinoTopsOnline.",
        skills: ["Laravel", "PHP", "Team Leadership", "Agile"],
        projects: "https://www.casinotopsonline.com/",
        pic: "https://cdn.simpleicons.org/laravel/000000"
    },
    {
        id: "t12",
        title: "Full Stack Developer (Contract)",
        start: "Feb 2022",
        end: "Jan 2024",
        description: "Delivered e-learning platforms like KeplerLearning as part of Proxify, integrating modern frontends with Laravel backends.",
        skills: ["Laravel", "React.js", "API Development"],
        projects: "https://keplerlearning.se/",
        pic: "https://cdn.simpleicons.org/laravel/000000"
    },
    {
        id: "t13",
        title: "Laravel Developer",
        start: "Sep 2021",
        end: "Dec 2022",
        description: "Built WSN.com from scratch with a custom CMS and optimized SEO strategies.",
        skills: ["Laravel", "PHP", "CMS Development"],
        projects: "https://www.wsn.com/",
        pic: "https://cdn.simpleicons.org/laravel/000000"
    },
    {
        id: "t14",
        title: "PHP Developer",
        start: "Oct 2020",
        end: "Sep 2021",
        description: "Developed a centralized WordPress theme for GiG affiliate sites like JohnSlots, supporting over 100 sites.",
        skills: ["PHP", "WordPress", "Twig"],
        projects: "https://www.johnslots.com/en/",
        pic: "https://cdn.simpleicons.org/wordpress/000000"
    },
    {
        id: "t15",
        title: "Web Developer",
        start: "May 2020",
        end: "Oct 2020",
        description: "Contributed to GiG gambling affiliate sites and developed the DigitalSchool LMS system.",
        skills: ["WordPress", "PHP", "GitLab"],
        projects: ["https://www.playcasinos.ca/", "https://app.digitalschool.tech/login"],
        pic: "https://cdn.simpleicons.org/php/000000"
    },
    {
        id: "t16",
        title: "Junior Web Developer",
        start: "Jun 2019",
        end: "May 2020",
        description: "Collaborated on Laravel-based projects, including DigitalSchool, with focus on debugging and new features.",
        skills: ["PHP", "Laravel"],
        projects: "https://app.digitalschool.tech/",
        pic: "https://cdn.simpleicons.org/googlescholar/000000"
    },
    {
        id: "t17",
        title: "Junior Application Developer (Contract)",
        start: "Jun 2018",
        end: "Oct 2018",
        description: "Worked on financial literacy platforms for students, creating engaging mobile/web app features.",
        skills: ["Mobile App Development", "React Native", "Firebase"],
        projects: "https://givenation.world/",
        pic: "https://cdn.simpleicons.org/react/000000"
    },
    {
        id: "t18",
        title: "Game Development Intern",
        start: "Jun 2018",
        end: "Sep 2018",
        description: "Developed immersive mobile games at Zombie Soup, utilizing Unity and C#.",
        skills: ["Unity", "C#"],
        projects: "https://zombiesoup.com/",
        pic: "https://cdn.simpleicons.org/unity/000000"
    }
];

const TimelineCard = ({ index, title, start, end, description, skills, projects, pic }) => {
    const truncatedDescription = description.length > 150
        ? description.substring(0, 150) + "..."
        : description;

    return (
        <div className="timeline-card">
            <div className="timeline-card-content">
                <div className="timeline-circle"></div>
                <div className="timeline-arrow"></div>
                <h2 className="timeline-title">{title}</h2>
                <h3 className="timeline-date">
                    {start} - {end}
                </h3>
                <p className="timeline-description">{truncatedDescription}</p>
                <div className="timeline-skills">
                    {skills.slice(0, 4).map((skill, i) => (
                        <span key={i} className="timeline-skill">
                            {skill}
                        </span>
                    ))}
                </div>
                {projects && (
                    <div className="timeline-projects">
                        <div className="timeline-projects-list">
                            {(Array.isArray(projects) ? projects : [projects]).map((project, index) => {
                                // Extract project name from URL or use generic name
                                const projectName = project.includes('http')
                                    ? new URL(project).hostname.replace('www.', '')
                                    : project;

                                return (
                                    <a
                                        key={index}
                                        href={project}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="timeline-project-link"
                                    >
                                        {projectName}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                )}
                <img src={pic} alt="Timeline icon" className="timeline-icon" />
            </div>
        </div>
    );
};

class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            circlePosition: 0,
        };
        this.timelineRef = React.createRef();
    }

    componentDidMount() {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll);
            this.handleScroll(); // Initial position

            const observerOptions = {
                root: null, // Use the viewport as the root
                rootMargin: '0px',
                threshold: 0.1 // Trigger when 10% of the card is visible
            };

            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        observer.unobserve(entry.target); // Stop observing once animated
                    }
                });
            }, observerOptions);

            const timelineCards = document.querySelectorAll('.timeline-card-content');
            timelineCards.forEach(card => this.observer.observe(card));
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            window.removeEventListener('scroll', this.handleScroll);
            if (this.observer) {
                const timelineCards = document.querySelectorAll('.timeline-card-content');
                timelineCards.forEach(card => this.observer.unobserve(card));
            }
        }
    }

    handleScroll = () => {
        if (this.timelineRef.current) {
            const circle = document.querySelector('.timeline-moving-circle');
            const timelineLine = document.querySelector('.timeline-line');

            if (circle && timelineLine) {
                const lineRect = timelineLine.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                // Get the absolute positions of the line
                const lineStart = lineRect.top + window.scrollY;
                const lineEnd = lineRect.bottom + window.scrollY - 130;
                const lineHeight = lineEnd - lineStart;

                // Calculate the scrollable height of the document
                const totalScrollableHeight = document.documentElement.scrollHeight - viewportHeight;
                const currentScroll = window.pageYOffset;

                // Calculate progress (0 to 1)
                const progress = Math.max(0, Math.min(1, currentScroll / totalScrollableHeight));

                // Calculate circle position
                const circlePosition = lineStart + (lineHeight * progress);

                // Clamp the circle position between line start and end minus the circle's height
                const clampedPosition = Math.max(lineStart, Math.min(lineEnd - circle.clientHeight, circlePosition));

                // Update state
                this.setState({ circlePosition: clampedPosition });
            }
        }
    };

    render() {
        return (
            <>
                {typeof window !== 'undefined' ? (
                    <div className="timeline-container" ref={this.timelineRef}>
                        <section className="timeline-section">
                            <div className="timeline-line"></div>
                            <div
                                className="timeline-moving-circle"
                                style={{ top: `${this.state.circlePosition}px` }}
                            ></div>
                            <div>
                                {timelineData.slice().reverse().map((item, index) => (
                                    <TimelineCard key={item.id} index={index} {...item} />
                                ))}
                            </div>
                        </section>
                    </div>
                ) : null}
            </>
        );
    }
}

export default Timeline;
