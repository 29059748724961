import React from "react";
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';

const NAVIGATION_ITEMS = [
    { label: '.home()', path: '/', isLink: true },
    { label: '.skills()', path: '#skills', isScrollable: true },
    { label: '.xp()', path: '#experience', isScrollable: true },
    { label: '.posts()', path: '/posts/', isLink: true },
    { label: '.timeline()', path: '/timeline/', isLink: true },
];

const Navigation = () => {
    const isClient = typeof window !== "undefined";
    const isHomePage = isClient && window.location.pathname === "/";

    const renderNavItem = ({ label, path, isLink, isScrollable }) => {
        if (isHomePage && isScrollable) {
            return (
                <a
                    key={label}
                    className="nav-link"
                    onClick={() => scrollTo(path)}
                    role="button"
                    tabIndex={0}
                >
                    {label}
                </a>
            );
        }

        if (isLink) {
            return path === '/' ? (
                <a key={label} className="nav-link" href={path}>
                    {label}
                </a>
            ) : (
                <Link key={label} className="nav-link" to={path}>
                    {label}
                </Link>
            );
        }

        // Fallback for non-homepage scrollable items
        return (
            <a key={label} className="nav-link" href="/">
                {label}
            </a>
        );
    };

    return (
        <div className="container pt-3 d-flex overflow-auto">
            <nav className="main-navbar d-flex flex-md-row flex-column navbar navbar-expand navbar-custom">
                <div className="d-flex flex-row">
                    {NAVIGATION_ITEMS.map(renderNavItem)}
                </div>
            </nav>
        </div>
    );
};

export default Navigation;